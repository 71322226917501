// sass-lint:disable class-name-format
@import '../../styles/common';

@import '../../components/NoteHtml';
@import '../../components/PushLoginRec';
@import '../../components/ActionsSharedAndFavorite';
@import '../../components/FeedBack';
@import '../../components/FeedBackMessageView';
@import '../../components/ContentsModule';
@import '../../components/CtaNewNotes';
@import '../../components/Fallback';
@import '../../components/FallbackSellerCoach';
@import '../../components/IconText';
@import '../../components/NoteTitle';
@import '../../components/NoteSubtitle';
@import '../../components/ShareButton';
@import '../../components/YoutubeVideo';
@import '../../components/Banner';
@import '../../components/RecoContainer';
@import '../../components/Carrousel';
@import '../../components/Card';
@import '../../components/CardContainer';
@import '../../components/Tag';
@import '../../components/CtaButton';
@import '../../components/Search';
@import '../../containers/SellerCoach';

.seller-coach-desktop {
  width: 90%;
  margin: 20px;
  padding: 10px 10px 0;
}

.seller-coach-mobile {
  margin-top: 20px !important;
}

.image__positioned img {
  max-width: 100%;
  height: auto;
}

.note-page {
  .sc-view-note {
    .sc-note_presentation_container {
      padding: 1.25em 1.25em 0;
    }

    .sc-backing-note-container {
      padding-bottom: 36px;
    }
  }

  .sc-view-note.fallback {
    display: flex;
    height: calc(100vh - 127px);
  }
}

.sc-card-container {
  margin: 36px 20px 0;
  padding: 20px;

  &-text {
    text-align: left;
  }

  .cta-button {
    margin-top: 16px;
  }

  .sc-carrousel-cards {
    padding: 0 14px 20px !important;
  }
}

.sc-note_presentation_container {
  .icon-text {
    height: 1.5em;
    margin-top: 1.5em;
    justify-content: start;

    .icon-custom {
      margin-right: 0.7em;
      padding-top: 5px;
    }

    .andes-typography {
      margin: 0;
      font-weight: 600;
      color: $andes-gray-550-solid;
    }
  }

  .cta-button.outline {
    margin-top: 20px;
    min-width: unset;
    padding: 0 20px 0 12px;
    height: 34px;
  }
}

.sc-backing-note-container .sc-reco-container {
  border: 1px solid $andes-gray-070-solid;
  box-shadow: 0 1px 2px $box-shadow;
  border-radius: 20px;
  padding: 20px 0;
  margin: 0 20px;
  width: initial;

  .cta-button.outline {
    min-width: unset;
    padding: 0 20px 0 12px;
  }
}

.sc-reco-container {
  &-tag-title {
    padding: 0 20px;

    .sc-reco-container-title {
      padding-left: 0;
    }
  }

  &-cards .cta-button {
    padding-left: 20px;
    color: $andes-blue-500;

    .icon-custom path {
      stroke: $andes-blue-500 !important;
      fill: $andes-blue-500 !important;
    }
  }
}
